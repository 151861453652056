.faq-container {
    max-width: 900px;
    margin: 30px auto;
    padding: 2.5rem;
    margin-top:5px;
    color: #f8f9fa;
    background-color: #212529;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.faq-container h2 {
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
    font-weight: 500;
}

.faq-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.faq-question {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 1.5rem 0;
    cursor: pointer;
    position: relative;
    padding-left: 40px;
    transition: color 0.3s ease;
}


.faq-question::before {
    content: '?';
    position: absolute;
    left: 0;
    top: 0;
    color: #fab005; 
    font-size: 26px;
    font-weight: 800;
}

.faq-question:hover, .faq-question.active {
    color: #fab005; 
}

.faq-answer {
    font-size: 1rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
    padding-left: 40px; 
    font-weight: 300;
    color: #dee2e6; 
}

.faq-answer.active {
    max-height: 150px; 
    padding-top: 1rem; 
}

.arrow{
    margin-left:10px;

}

@media (max-width: 768px) {
    .faq-container {
        padding: 1.5rem;
    }

    .faq-container h2 {
        font-size: 2rem;
    }

    .faq-question {
        font-size: 1rem;
    }
}
