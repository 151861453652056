.MainContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    overflow-y: auto;
}

.HeroMain {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    z-index: 1;
    position: relative;
    height: 100vh; 
    width: 100vw;
}

.ScrollableContent {
    margin-top: 100vh; 
    padding: 20px;
    background-color: #f0f0f0;
    height: auto; 
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; 
    justify-content: center;
    padding: 20px;
    width: 100%;
    background-color: #dfd1be;
}

.services-image {
    flex: 1;
    max-width: 40%; 
    padding: 10px;
}

.services-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services-content {
    flex: 1;
    max-width: 30%; 
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff; 
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.services-content h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.service {
    margin-bottom: 20px;
}

.service h3 {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 10px;
}

.service p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .services-container {
        flex-direction: column;
    }

    .services-image,
    .services-content {
        max-width: 100%;
    }
}
