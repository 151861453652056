.services-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; 
    justify-content: center;
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    background-color: #dfd1be;
    box-sizing: border-box;
}

.services-image {
    flex: 1;
    max-width: 40%; 
    padding: 10px;
    margin-top: 13px;
    box-sizing: border-box;
}

.services-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services-content {
    flex: 1;
    max-width: 50%; 
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff; 
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 13px;
}

.services-content h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.service {
    margin-bottom: 20px;
}

.service h3 {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 10px;
}

.service p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

@media (max-width: 1024px) {
    .services-container {
        flex-direction: column;
        align-items: center;
    }

    .services-image,
    .services-content {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .services-content h2 {
        font-size: 1.5rem;
    }

    .service h3 {
        font-size: 1.2rem;
    }

    .service p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .services-container {
        padding: 10px;
    }

    .services-image {
        padding: 5px;
    }

    .services-content {
        padding: 10px;
    }

    .services-content h2 {
        font-size: 1.2rem;
    }

    .service h3 {
        font-size: 1rem;
    }

    .service p {
        font-size: 0.8rem;
    }
}
