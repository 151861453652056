.NavBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
    position:absolute;
    padding: 10px 20px;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    box-sizing: border-box;
    z-index: 1000;
    width: 100vw;
  }

  
  .leftContainer {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 50px;
    width: 50px;
    object-fit: contain;
  }
  
  .brandName {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .navButton {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .navButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .rightContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .hamburger div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px;
    transition: all 0.3s ease;
  }
  
  @media (max-width: 768px) {
    .NavBar {
      padding: 10px;
      height: auto;
      justify-content: space-between;
    }
  
    .logo {
      height: 40px;
      width: 40px;
    }
  
    .brandName {
      font-size: 1.5rem;
    }
  
    .rightContainer {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      background-color: black;
      position: absolute;
      top: 70px;
      left: 0;
      padding: 10px 20px;
      z-index: 999;
    }
  
    .rightContainer.active {
      display: flex;
    }
  
    .hamburger {
      display: flex;
    }
  }
  