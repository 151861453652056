body {
    background-color: #dfd1be;
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    padding:10px;
  }
  
  .about-us-container {
    max-width: 800px;
    margin: 0 auto; 
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .about-us-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .about-us-container p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
  }
  