.get-started-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.get-started-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.get-started-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.get-started-container label {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}

.get-started-container input[type="text"],
.get-started-container input[type="email"],
.get-started-container input[type="file"] {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.get-started-container input[type="file"] {
  padding: 5px;
}

.get-started-container button {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #FF6A00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.get-started-container button:hover {
  background-color: #e65c00;
}
